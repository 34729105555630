<template>
  <div class="bg lbg" style="width: 100%; height: 100%">
    <div class="loginBg">
      <div class="right" v-show="!showRegister && !showReset">
        <div class="title">
          <img src="../../assets/login.png" alt="" />
          <i>海联川科技</i>
        </div>

        <div v-show="!showExchange">
          <div class="exchangeBox cp" @click="showExchange = true">卡密兑换</div>

          <el-tabs v-model="activeName">
            <el-tab-pane label="账号登录" name="first">
              <div class="mb30">
                <el-input placeholder="请输入您的账号" size="medium" prefix-icon="el-icon-user" v-model="username"></el-input>
              </div>
              <div class="mb20">
                <el-input placeholder="请输入您的密码" prefix-icon="el-icon-lock" @keyup.enter.native="login" show-password v-model="password"></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane label="邮箱登录" name="second">
              <div class="mb30 iptBox">
                <el-input placeholder="请输入您的邮箱" size="medium" prefix-icon="el-icon-user" v-model="email"></el-input>
              </div>
              <div class="mb20 iptBox">
                <el-input placeholder="请输入您的验证码" prefix-icon="el-icon-lock" @keyup.enter.native="login" show-password v-model="code">
                  <template slot="append">
                    <el-button v-show="!codeShow" type="primary" @click="send">获取邮箱验证码</el-button>
                    <el-button v-show="codeShow" type="primary" disabled>{{ count }}秒后重试</el-button>
                  </template>
                </el-input>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div class="dfc fs13">
            <span class="cp" style="color: #409eff" @click="showRegister = true">立即注册</span>
            <span class="cp" @click="showReset = true">忘记密码？</span>
          </div>

          <el-button type="primary" class="login" style="" size="medium" @click="login">登录</el-button>
          <div class="chek">
            <el-checkbox v-model="checked">
              我已阅读理解并同意 <span style="color: #409eff" @click="openDoc('yh')">《用户协议》</span>和<span style="color: #409eff" @click="openDoc('ys')">《隐私协议》</span>
            </el-checkbox>
          </div>
        </div>
        <div v-show="showExchange" class="mt20">
          <el-page-header @back="showExchange = false" content="卡密兑换"> </el-page-header>

          <div class="mb30 mt30">
            <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="username"></el-input>
          </div>
          <div class="mb20">
            <el-input placeholder="请输入兑换码" prefix-icon="el-icon-lock" @keyup.enter.native="exchange" v-model="code"></el-input>
          </div>
          <el-button type="primary" class="login" style="" size="medium" @click="exchange">兑换</el-button>
        </div>
      </div>

      <Register v-show="showRegister" @close="close"></Register>
      <resetPassword v-show="showReset" @close="close"></resetPassword>
    </div>

    <!-- 控制 -->
    <div class="contrl">
      <div @click="handleMinus">
        <i class="el-icon-minus"></i>
      </div>
      <div @click="handleClose">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getUser, use_cami, send_code } from "@/api/user.js";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import Register from "@/components/register.vue";
import resetPassword from "@/components/resetPassword.vue";
export default {
  components: {
    Register,
    resetPassword,
  },
  data() {
    return {
      username: "",
      password: "",
      email: "",
      code: "",
      activeName: "first",
      count: "",
      codeShow: false,
      timer: null,
      showRegister: false,
      showReset: false,
      checked: false,
      showExchange: false,
    };
  },
  mounted() {
    this.listenerClose();
  },
  methods: {
    listenerClose() {
      this.$ipc.invoke(ipcApiRoute.closeWin).then((res) => {
        console.log("关闭窗口");
      });
    },
    openDoc(type) {
      console.log(111);
      this.$ipc.invoke(ipcApiRoute.openPath, { fileName: type == "ys" ? "privacy.doc" : "user.doc" }).then((res) => {
        console.log("打开地址");
      });
    },
    async send() {
      if (!this.email) {
        this.$message.error("请输入邮箱");
        return;
      }
      let clicktime = new Date().getTime() + 60000; //未来60秒，这里也毫秒为单位
      sessionStorage.setItem("EndTime", JSON.stringify(clicktime)); //存入sessionStorage
      /* this.$message.success("发送成功");
      this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去 */

      let res = await send_code({
        email: this.email,
        event: 2,
      });
      if (res.code == 200) {
        this.$message.success("发送成功");
        this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去
      } else {
        this.$message.error(res.msg);
      }
    },
    timeDown(time) {
      if (!this.timer) {
        this.count = Math.ceil((JSON.parse(time) - new Date().getTime()) / 1000); //取出计时
        console.log(111, this.count);

        this.codeShow = true;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.codeShow = false;
            clearInterval(this.timer); //清除计时器
            this.timer = null;
            sessionStorage.removeItem("EndTime"); //计时完后清除sessionStorage
          }
        }, 1000);
      }
    },
    exchange() {
      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.code) {
        this.$message.error("卡密不能为空");
        return;
      }

      let params = {
        username: this.username,
        code: this.code,
      };
      use_cami(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.username = "";
          this.code = "";
          this.showExchange = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.$ipc.invoke(ipcApiRoute.closeApp).then((r) => {});
    },
    handleMinus() {
      this.$ipc.invoke(ipcApiRoute.handleApp, "mini").then((r) => {});
    },
    login() {
      // localStorage.setItem('token', '111')
      // this.$router.push({ name: 'video' })

      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.password) {
        this.$message.error("密码不能为空");
        return;
      }
      if (!this.checked) {
        this.$message.error("请勾选用户协议");
        return;
      }

      let params = {
        username: this.username,
        password: this.password,
      };
      login(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          localStorage.setItem("token", res.data.token);
          getUser({ token: res.data.token }).then((res1) => {
            if (res1.code == "200") {
              localStorage.setItem("userInfo", JSON.stringify(res1.data));
              this.$router.push({ name: "home" });
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    close() {
      this.showRegister = false;
      this.showReset = false;
    },
  },
};
</script>
<style lang="less" scoped>
.lbg {
  background: url(~@/assets/img/bg.png) no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
}
.loginBg {
  width: 24vw;
  height: 28vw;
  background-color: #fff;
  -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: relative;
  margin-left: 10vw;

  .login {
    width: 94%;
    background: #2773f8;
    margin: 40px 3% 30px;
    height: 45px;
  }

  .right {
    width: 24vw;
    padding: 40px 50px;
    height: inherit;
    position: absolute;
    top: 0;
    text-align: left;
    font-size: 15px;

    .title {
      font-weight: bold;
      font-size: 24px;
      // padding-bottom: 10px;
      margin-bottom: 0px;
      img {
        width: 88px;
        height: 75px;
      }
    }
  }
}

.exchangeBox {
  color: #409eff;
  text-align: right;
  position: relative;
  top: 30px;
  font-size: 14px;
  width: 80px;
  left: 240px;
  z-index: 999;
}

::v-deep {
  .el-input__inner {
    background: #f7f7f7 !important;
    border-radius: 6px !important;
    border: 0 !important;
  }
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
.contrl {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 20px;
  justify-content: end;
  position: absolute;
  right: 0;
  width: 100vw;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  -webkit-app-region: drag;

  div {
    width: 30px;
    line-height: 28px;
    -webkit-app-region: no-drag;
  }
}
</style>
